  <!-- 生态人文 -->
<template>
  <div class="move-frame">
    <div class="body">
      <top showTitle="走进黑水" :childNavs="navs"></top>
      <div class="content">
        <div class="left">
          <!-- 一级 -->
          <div class="type_item">
            <div class="show">
              <div class="label">生态人文</div>
            </div>
            <!-- 二级 -->
            <div class="sub_list">
              <div
                :class="{ sub_item: true, sub_active: activeSubType == '社区' }"
                @click="changeType('社区')"
              >
                <div class="sub_title">
                  <img src="@/assets/move/ecology1.png" alt="" class="icon" />
                  <img
                    src="@/assets/move/ecology1_active.png"
                    alt=""
                    class="active_icon"
                  />
                  <div class="text">社区</div>
                </div>
              </div>
              <div
                :class="{
                  sub_item: true,
                  sub_active: activeSubType == '养老院',
                }"
                @click="changeType('养老院')"
              >
                <div class="sub_title">
                  <img src="@/assets/move/ecology2.png" alt="" class="icon" />
                  <img
                    src="@/assets/move/ecology2_active.png"
                    alt=""
                    class="active_icon"
                  />
                  <div class="text">养老院</div>
                </div>
              </div>
              <div
                :class="{
                  sub_item: true,
                  sub_active: activeSubType == '文娱设施',
                }"
                @click="changeType('文娱设施')"
              >
                <div class="sub_title">
                  <img src="@/assets/move/ecology3.png" alt="" class="icon" />
                  <img
                    src="@/assets/move/ecology3_active.png"
                    alt=""
                    class="active_icon"
                  />
                  <div class="text">文娱设施</div>
                </div>
              </div>
              <div
                :class="{ sub_item: true, sub_active: activeSubType == '学校' }"
                @click="changeType('学校')"
              >
                <div class="sub_title">
                  <img src="@/assets/move/ecology4.png" alt="" class="icon" />
                  <img
                    src="@/assets/move/ecology4_active.png"
                    alt=""
                    class="active_icon"
                  />
                  <div class="text">学校</div>
                </div>
              </div>
              <div
                :class="{ sub_item: true, sub_active: activeSubType == '医院' }"
                @click="changeType('医院')"
              >
                <div class="sub_title">
                  <img src="@/assets/move/ecology5.png" alt="" class="icon" />
                  <img
                    src="@/assets/move/ecology5_active.png"
                    alt=""
                    class="active_icon"
                  />
                  <div class="text">医院</div>
                </div>
              </div>
              <div
                :class="{ sub_item: true, sub_active: activeSubType == '住宅' }"
                @click="changeType('住宅')"
              >
                <div class="sub_title">
                  <img src="@/assets/move/ecology6.png" alt="" class="icon" />
                  <img
                    src="@/assets/move/ecology6_active.png"
                    alt=""
                    class="active_icon"
                  />
                  <div class="text">住宅</div>
                </div>
              </div>
              <div
                :class="{
                  sub_item: true,
                  sub_active: activeSubType == '资源回收',
                }"
                @click="changeType('资源回收')"
              >
                <div class="sub_title">
                  <img src="@/assets/move/ecology7.png" alt="" class="icon" />
                  <img
                    src="@/assets/move/ecology7_active.png"
                    alt=""
                    class="active_icon"
                  />
                  <div class="text">资源回收</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="center_title">
            <i class="arrow el-icon-caret-right"></i>
            <div class="center_label">{{ activeSubType }}</div>
          </div>
          <div id="container"></div>
        </div>
        <div class="right">
          <div class="choose">
            <div class="choice active">{{ activeSubType }}概述</div>
          </div>
          <div class="desc">
            <div class="build" v-for="(item, idx) in list" :key="item.id">
              <div class="title">
                <div class="num">{{ idx + 1 }}</div>
                <div class="name">{{ item.ecologicalName }}</div>
              </div>
              <div class="describ">{{ item.summary }}</div>
            </div>
          </div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import top from "../../components/top.vue";
import routes from "../../router/moveWater";

import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: { top, Bottom },
  data() {
    return {
      navs: routes,
      map: null,
      AMap: null,
      activeType: "", // 第一级激活类
      activeSubType: "", // 第二级激活类
      activeLastType: "", // 第三级激活类
      list: [], // 右侧列表
      infoWindow: null, // 信息窗体载体
    };
  },
  created() {
    this.changeType("社区");
  },
  mounted() {},
  methods: {
    async changeType(name) {
      if (this.activeSubType == name) {
        return;
      }
      this.activeSubType = name;
      const params = {
        pageIndex: 1,
        pageSize: 100,
        ecologicalType: name,
      };
      let res = await this.$api.getEcological(params);
      this.list = res.data.list;
      this.map ? this.initCover() : this.initMap();
    },
    initMap() {
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
          });
          this.map.addControl(new AMap.Scale());

          this.infoWindow = new AMap.InfoWindow({
            autoMove: true,
            offset: new AMap.Pixel(0, -30),
          });
          this.initCover();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    initCover() {
      this.map.clearMap();
      this.list.forEach((ele, idx) => {
        // 自定义展示样式
        let div = document.createElement("div");
        div.style.cssText =
          "width:25px;height:25px;border-radius:50%;background:#0e5acd;color:#fff;font-size:14px;text-align:center;line-height:25px";
        div.innerHTML = idx + 1;

        const path = JSON.parse(ele.longituAndLatitu);
        let marker = new this.AMap.Marker({
          position: path[0],
          content: div,
          offset: new this.AMap.Pixel(-13, -30),
        });
        // 自定义信息窗体
        marker.content = `<span style='white-space: nowrap;'>${idx + 1}、${
          ele.ecologicalName
        }</span>`;
        marker.on("mouseover", this.openInfo);
        marker.on("mouseout", this.closeInfo);
        marker.setMap(this.map);
      });
      this.map.setFitView(null, false, [100, 100, 100, 100]);
    },
    openInfo(e) {
      this.infoWindow.setContent(e.target.content);
      this.infoWindow.open(this.map, e.target.getPosition());
    },
    closeInfo(e) {
      this.infoWindow.close(this.map, e.target.getPosition());
    },
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 100%;
  background-color: rgb(250 251 253 / 70%);
  position: relative;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
  padding: 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .left {
    width: 212px;
    margin-right: 20px;

    .type_item {
      height: 100%;
      background-color: #fff;
      padding: 10px;
      margin-bottom: 10px;
      color: #cfcfcf;

      .show {
        font-weight: bold;
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          width: 3px;
          height: 60%;
          border-radius: 10px;
          background-color: #cfcfcf;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .label {
          padding-left: 10px;
          color: #333;
          font-size: 18px;
        }
        .minus {
          display: none;
        }
      }

      .sub_list {
        width: 100%;
        height: calc(100% - 30px);
        padding: 7px 0;
        box-sizing: border-box;
        margin-top: 7px;
        color: #333;
        overflow: auto;

        .sub_item {
          cursor: pointer;
          border-radius: 5px;
          margin-bottom: 10px;
          padding: 0 20px;

          &:hover {
            color: #fff;
            background-color: $theme;

            .sub_title {
              border-color: transparent;
              .icon {
                display: none;
              }
              .active_icon {
                display: block;
              }
            }
          }
          &:last-child .sub_title {
            border-bottom: 0;
          }
          .sub_title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;

            img {
              width: 22px;
            }
            .active_icon {
              display: none;
            }
            .text {
              flex-grow: 1;
              margin-left: 30px;
            }
          }
        }
        .sub_active {
          color: #fff;
          background-color: $theme;

          .sub_title {
            border-color: transparent;

            .icon {
              display: none;
            }
            .active_icon {
              display: block;
            }
          }
        }
      }
    }

    .active {
      color: $theme;
      .show {
        &::before {
          background-color: $theme;
        }
        .label {
          color: $theme;
        }
        .plus {
          display: none;
        }
        .minus {
          display: block;
        }
      }
    }
  }
  .center {
    flex-grow: 1;
    position: relative;

    #container {
      width: 100%;
      height: 100%;
    }

    &_title {
      width: 95%;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      background-color: rgba(246, 251, 255, 0.7);
      border: 1px solid #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        color: $theme;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      .center_label {
        margin-left: 35px;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .right {
    width: 500px;
    margin-left: 20px;
    background-color: #fff;
    padding: 10px 15px;
    box-sizing: border-box;

    .choose {
      height: 40px;
      border: 1px solid #eee;
      border-radius: 5px;
      display: flex;
      justify-content: space-evenly;
      font-size: 18px;
      line-height: 40px;

      .choice {
        height: 100%;
        cursor: pointer;
        position: relative;

        &:hover {
          color: $theme;

          &::after {
            width: 100%;
          }
        }
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          border-radius: 5px;
          background-color: $theme;
          position: absolute;
          left: 0;
          bottom: -1px;
          transition: width 0.2s;
        }
      }
      .active {
        font-weight: bold;
        color: $theme;

        &::after {
          width: 100%;
        }
      }
    }
    .desc {
      height: calc(100% - 47px);
      overflow: auto;
      margin-top: 5px;
      font-size: 14px;
      padding: 0 5px;
      text-align: justify;

      .build {
        padding: 20px 0;
        border-bottom: 1px solid #eee;

        .title {
          display: flex;
          align-items: center;

          .num {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            color: #fff;
            background-color: $theme;
          }
          .name {
            margin-left: 10px;
          }
        }
        .describ {
          margin-top: 14px;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
}
@media (max-width: 1700px) {
  .content .right {
    width: 400px;

    .choose {
      font-size: 15px;
    }
    .desc {
      font-size: 13px;
    }
  }
}
@media (max-width: 1500px) {
  .content {
    .center_title .center_label {
      font-size: 16px;
    }
    .left {
      width: 200px;

      .type_item {
        font-size: 14px;

        .show .label {
          font-size: 16px;
        }
      }
    }
    .right {
      width: 300px;
    }
  }
}
</style>

