var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"move-frame"},[_c('div',{staticClass:"body"},[_c('top',{attrs:{"showTitle":"走进黑水","childNavs":_vm.navs}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"type_item"},[_vm._m(0),_c('div',{staticClass:"sub_list"},[_c('div',{class:{ sub_item: true, sub_active: _vm.activeSubType == '社区' },on:{"click":function($event){return _vm.changeType('社区')}}},[_vm._m(1)]),_c('div',{class:{
                sub_item: true,
                sub_active: _vm.activeSubType == '养老院',
              },on:{"click":function($event){return _vm.changeType('养老院')}}},[_vm._m(2)]),_c('div',{class:{
                sub_item: true,
                sub_active: _vm.activeSubType == '文娱设施',
              },on:{"click":function($event){return _vm.changeType('文娱设施')}}},[_vm._m(3)]),_c('div',{class:{ sub_item: true, sub_active: _vm.activeSubType == '学校' },on:{"click":function($event){return _vm.changeType('学校')}}},[_vm._m(4)]),_c('div',{class:{ sub_item: true, sub_active: _vm.activeSubType == '医院' },on:{"click":function($event){return _vm.changeType('医院')}}},[_vm._m(5)]),_c('div',{class:{ sub_item: true, sub_active: _vm.activeSubType == '住宅' },on:{"click":function($event){return _vm.changeType('住宅')}}},[_vm._m(6)]),_c('div',{class:{
                sub_item: true,
                sub_active: _vm.activeSubType == '资源回收',
              },on:{"click":function($event){return _vm.changeType('资源回收')}}},[_vm._m(7)])])])]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"center_title"},[_c('i',{staticClass:"arrow el-icon-caret-right"}),_c('div',{staticClass:"center_label"},[_vm._v(_vm._s(_vm.activeSubType))])]),_c('div',{attrs:{"id":"container"}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"choose"},[_c('div',{staticClass:"choice active"},[_vm._v(_vm._s(_vm.activeSubType)+"概述")])]),_c('div',{staticClass:"desc"},_vm._l((_vm.list),function(item,idx){return _c('div',{key:item.id,staticClass:"build"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"num"},[_vm._v(_vm._s(idx + 1))]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.ecologicalName))])]),_c('div',{staticClass:"describ"},[_vm._v(_vm._s(item.summary))])])}),0)])]),_c('bottom')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show"},[_c('div',{staticClass:"label"},[_vm._v("生态人文")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/move/ecology1.png"),"alt":""}}),_c('img',{staticClass:"active_icon",attrs:{"src":require("@/assets/move/ecology1_active.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("社区")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/move/ecology2.png"),"alt":""}}),_c('img',{staticClass:"active_icon",attrs:{"src":require("@/assets/move/ecology2_active.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("养老院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/move/ecology3.png"),"alt":""}}),_c('img',{staticClass:"active_icon",attrs:{"src":require("@/assets/move/ecology3_active.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("文娱设施")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/move/ecology4.png"),"alt":""}}),_c('img',{staticClass:"active_icon",attrs:{"src":require("@/assets/move/ecology4_active.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("学校")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/move/ecology5.png"),"alt":""}}),_c('img',{staticClass:"active_icon",attrs:{"src":require("@/assets/move/ecology5_active.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("医院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/move/ecology6.png"),"alt":""}}),_c('img',{staticClass:"active_icon",attrs:{"src":require("@/assets/move/ecology6_active.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("住宅")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/move/ecology7.png"),"alt":""}}),_c('img',{staticClass:"active_icon",attrs:{"src":require("@/assets/move/ecology7_active.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("资源回收")])])
}]

export { render, staticRenderFns }